var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isHidden),expression:"!isHidden"}],style:(_vm.computedStyle)},[_c('span',{staticClass:"registry_fields_label xref_multi_field_label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.tooltip)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.tooltip}},[_c('i',{staticClass:"el-icon-question"})]):_vm._e()],1),_c('el-form-item',{attrs:{"prop":_vm.name,"rules":[
    { required: _vm._isRequired, message: _vm.$locale.main.message.required_field, trigger: 'blur' }
  ]}},[_c('treeselect',{ref:"treeselect",staticClass:"custom_scrollbar xref_multi_field",attrs:{"placeholder":_vm.placeholder || _vm.$locale.main.placeholder.select,"options":_vm.computedOptions,"load-options":_vm.loadOptions,"disabled":_vm.readonly,"normalizer":_vm.normalizer,"clear-on-select":true,"clear-value-text":_vm.treeXrefField.clearValueText,"no-children-text":_vm.treeXrefField.noChildrenText,"loading-text":_vm.$locale.main.message.loading,"no-options-text":_vm.treeXrefField.noOptionsText,"no-results-text":_vm.treeXrefField.noResultsText,"match-keys":_vm.treeXrefField.matchKeys,"disable-branch-nodes":(!_vm.recursiveGroup || !!_vm.groupBy),"async":true,"disable-immediate-search":true,"cache-options":false,"append-to-body":true,"searchable":true,"multiple":true,"limit":_vm.limit,"limit-text":_vm.treeXrefField.limitText},on:{"open":_vm.onMenuOpen,"search-change":_vm.onSearch},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
  var node = ref.node;
  var shouldShowCount = ref.shouldShowCount;
  var count = ref.count;
  var labelClassName = ref.labelClassName;
  var countClassName = ref.countClassName;
return [(!node.raw.isLoadingLabel)?_c('label',{class:labelClassName,staticStyle:{"color":"#606266"},attrs:{"title":node.label}},[_vm._v(" "+_vm._s(node.label)+" "),(node.raw.children_count)?_c('span',{class:countClassName},[_vm._v("("+_vm._s(node.raw.children_count)+")")]):_vm._e()]):_c('div',{staticClass:"vue-treeselect__loading-tip"},[_c('div',{staticClass:"vue-treeselect__icon-container"},[_c('span',{staticClass:"vue-treeselect__icon-loader"})]),_c('span',{staticClass:"vue-treeselect__tip-text vue-treeselect__loading-tip-text"},[_vm._v(" "+_vm._s(_vm.$locale.main.message.loading)+" ")])])]}}]),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}},[_c('template',{slot:"before-list"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSearchTip),expression:"showSearchTip"}],staticClass:"vue-treeselect__tip vue-treeselect__seacrh-promt-tip"},[_c('div',{staticClass:"vue-treeselect__icon-container"},[_c('span',{staticClass:"vue-treeselect__icon-warning"})]),_c('span',{staticClass:"vue-treeselect__tip-text vue-treeselect__seacrh-promt-tip-text"},[_vm._v(" "+_vm._s(_vm.treeXrefField.pressEnterToSearchText)+" ")])])])],2)],1),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }